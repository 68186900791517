import { all, takeLatest } from 'redux-saga/effects' // eslint-disable-line
import actionTypes from './actiontypes' // eslint-disable-line
import {
  getEquipmentTypeAction,
  setEquipmentTypeAction,
  editEquipmentTypeAction,
  delEquipmentTypeAction,
  getEquipmentAction,
  setEquipmentAction,
  editEquipmentAction,
  delEquipmentAction,
  runEquipmentManualAction,
} from './actions'

/* ======== Equipment type ======== */
function* watchGetEquipmentTypeAction() {
  yield takeLatest(actionTypes.GET_DATA_EQUIPMENT_TYPE, getEquipmentTypeAction)
}

function* watchSetEquipmentTypeAction() {
  yield takeLatest(actionTypes.SET_DATA_EQUIPMENT_TYPE, setEquipmentTypeAction)
}

function* watchEditEquipmentTypeAction() {
  yield takeLatest(actionTypes.EDIT_DATA_EQUIPMENT_TYPE, editEquipmentTypeAction)
}

function* watchDelEquipmentTypeAction() {
  yield takeLatest(actionTypes.DELETE_DATA_EQUIPMENT_TYPE, delEquipmentTypeAction)
}

/* ======== Equipment ======== */
function* watchGetEquipmentAction() {
  yield takeLatest(actionTypes.GET_DATA_EQUIPMENT, getEquipmentAction)
}

function* watchSetEquipmentAction() {
  yield takeLatest(actionTypes.SET_DATA_EQUIPMENT, setEquipmentAction)
}

function* watchEditEquipmentAction() {
  yield takeLatest(actionTypes.EDIT_DATA_EQUIPMENT, editEquipmentAction)
}

function* watchRunEquipmentManualAction() {
  yield takeLatest(actionTypes.RUN_MANUAL_DATA_EQUIPMENT, runEquipmentManualAction)
}

function* watchDelEquipmentAction() {
  yield takeLatest(actionTypes.DELETE_DATA_EQUIPMENT, delEquipmentAction)
}

export default function* rootSaga() {
  yield all([
    watchGetEquipmentTypeAction(),
    watchSetEquipmentTypeAction(),
    watchEditEquipmentTypeAction(),
    watchDelEquipmentTypeAction(),
    watchGetEquipmentAction(),
    watchSetEquipmentAction(),
    watchEditEquipmentAction(),
    watchDelEquipmentAction(),
    watchRunEquipmentManualAction(),
  ])
}
