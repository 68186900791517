/* eslint-disable import/prefer-default-export */
import apiClient from 'services/axios'

/* ======== Equipment Type======== */

export async function fetchEquipmentTypeData() {
  return apiClient
    .get(`/rfidsystems/equipmenttype`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function setEquipmentTypeData(data) {
  return apiClient
    .post(`/rfidsystems/equipmenttype`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function editEquipmentTypeData(data) {
  return apiClient
    .put(`/rfidsystems/equipmenttype`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function delEquipmentTypeData(data) {
  return apiClient
    .delete(`/rfidsystems/equipmenttype`, {
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    })
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

/* ======== Equipment ======== */
export async function fetchEquipmentData() {
  return apiClient
    .get(`/rfidsystems/equipment`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function setEquipmentData(data) {
  return apiClient
    .post(`/rfidsystems/equipment`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function editEquipmentData(data) {
  return apiClient
    .put(`/rfidsystems/equipment`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function runEquipmentManualData(data) {
  return apiClient
    .post(`/rfidsystems/equipment/runmanual`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function delEquipmentData(data) {
  return apiClient
    .delete(`/rfidsystems/equipment`, {
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    })
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}
