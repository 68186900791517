const actiontypes = {
  SET_STATE: 'equipment/SET_STATE',
  SET_STATE_DATA: 'equipment/SET_STATE_DATA',

  SET_STATE_EQUIPMENT_TYPE_DATA: 'equipment/SET_STATE_EQUIPMENT_TYPE_DATA',
  GET_DATA_EQUIPMENT_TYPE: 'equipment/GET_DATA_EQUIPMENT_TYPE',
  SET_DATA_EQUIPMENT_TYPE: 'equipment/SET_DATA_EQUIPMENT_TYPE',
  EDIT_DATA_EQUIPMENT_TYPE: 'equipment/EDIT_DATA_EQUIPMENT_TYPE',
  DELETE_DATA_EQUIPMENT_TYPE: 'equipment/DELETE_DATA_EQUIPMENT_TYPE',

  SET_STATE_EQUIPMENT_DATA: 'equipment/SET_STATE_EQUIPMENT_DATA',
  GET_DATA_EQUIPMENT: 'equipment/GET_DATA_EQUIPMENT',
  SET_DATA_EQUIPMENT: 'equipment/SET_DATA_EQUIPMENT',
  EDIT_DATA_EQUIPMENT: 'equipment/EDIT_DATA_EQUIPMENT',
  RUN_MANUAL_DATA_EQUIPMENT: 'equipment/RUN_MANUAL_DATA_EQUIPMENT',
  DELETE_DATA_EQUIPMENT: 'equipment/DELETE_DATA_EQUIPMENT',
}

export default actiontypes
