import actions from './actions'

const initialState = {
  id: '',
  name: '',
  username: '',
  surname: '',
  note: '',
  created: '',
  acctype: '',
  division: '',
  authorized: process.env.REACT_APP_AUTHENTICATED || true, // false is default value
  loading: false,
  activate: {},
  user: [],

  isLoading: false,
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_STATE_USER:
      return { ...state, user: action.payload }
    default:
      return state
  }
}
