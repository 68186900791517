/* eslint-disable import/prefer-default-export */
import { call, put } from 'redux-saga/effects'
import actionTypes from './actiontypes'
import { fetchMonitorProcessData } from '../../services/dashboard'

export function* getMonitorProcessAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } })
    const payload = yield call(fetchMonitorProcessData)
    yield put({ type: actionTypes.SET_STATE_MONITOR_PROCESS_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}
