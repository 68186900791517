import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import equipments from './equipments/sagas'
import managements from './managements/sagas'
import dashboards from './dashboards/sagas'
import reports from './reports/sagas'

export default function* rootSaga() {
  yield all([user(), menu(), settings(), equipments(), managements(), dashboards(), reports()])
}
