import { all, takeLatest } from 'redux-saga/effects' // eslint-disable-line
import actionTypes from './actiontypes' // eslint-disable-line
import {
  setCategoryAction,
  getCategoryAction,
  editCategoryAction,
  delCategoryAction,
  getFabricDetailAction,
  setFabricDetailAction,
  editFabricDetailAction,
  delFabricDetailAction,
  getHospitalAction,
  setHospitalAction,
  editHospitalAction,
  delHospitalAction,
  getFabricWastAction,
  setFabricWastAction,
  editFabricWastAction,
  delFabricWastAction,
  getFabricLossCountAction,
  addOreditFabricLossCountAction,
  getSpecialCaseAction,
  setSpecialCaseAction,
  editSpecialCaseAction,
  delSpecialCaseAction,
  getSpecialTagAction,
  setOReditSpecialTagAction,
  delSpecialTagAction,
  getRFIDTagReaderWorkerAction,
  editRFIDTagReaderWorkerAction,
  runEquipmentInfoManualAction,
  getRFIDTagInfoReaderWorkerAction,
  editRFIDTagInfoReaderWorkerAction,
  delRFIDTagInfoReaderWorkerAction,
  getFabricInfoAction,
  setORupdateFabricInfoAction,
  editFabricInfoAction,
  delFabricInfoAction,
  getCompanyAction,
  setCompanyAction,
  editCompanyAction,
  delCompanyAction,
  runEquipmentCompanyAction,
  getEquipmentCompanyAction,
  delEquipmentCompanyAction,
  getCompanyTagAction,
  setOReditCompanyTagAction,
  delCompanyTagAction,
  getLocationWardAction,
  setLocationWardAction,
  setFabricLocationWardAction,
  delFabricLocationWardAction,
  delLocationWardAction,
  getFabricExpiredAction,
  addOreditFabricExpiredUsedAction,
  addOreditFabricExpiredMonthAction,
  runEquipmentRemoveRFIDWastingTagAction,
  getEquipmentRemoveRFIDWastingTagAction,
  delEquipmentRemoveRFIDWastingTagAction,
} from './actions'

/* ======== remove RFID wasting tag worker ======== */
function* watchRunEquipmentRemoveRFIDWastingTagAction() {
  yield takeLatest(
    actionTypes.EDIT_DATA_READERWORKER_REMOVE_WASTING_TAG,
    runEquipmentRemoveRFIDWastingTagAction,
  )
}

function* watchGetEquipmentRemoveRFIDWastingTagAction() {
  yield takeLatest(
    actionTypes.GET_DATA_READERWORKER_REMOVE_WASTING_TAG,
    getEquipmentRemoveRFIDWastingTagAction,
  )
}

function* watchDelEquipmentRemoveRFIDWastingTagAction() {
  yield takeLatest(
    actionTypes.DELETE_DATA_READERWORKER_REMOVE_WASTING_TAG,
    delEquipmentRemoveRFIDWastingTagAction,
  )
}

/* ======== Fabric Expired ======== */
function* watchGetFabricExpiredAction() {
  yield takeLatest(actionTypes.GET_DATA_FABRICEXPIRED, getFabricExpiredAction)
}

function* watchAddOrEditFabricExpiredUsedAction() {
  yield takeLatest(actionTypes.SET_DATA_FABRICEXPIREDUSED, addOreditFabricExpiredUsedAction)
}

function* watchAddOreditFabricExpiredMonthAction() {
  yield takeLatest(actionTypes.SET_DATA_FABRICEXPIREDMONTH, addOreditFabricExpiredMonthAction)
}

/* ======== Fabric Category ======== */
function* watchSetCategoryAction() {
  yield takeLatest(actionTypes.SET_DATA_CATEGORY, setCategoryAction)
}

function* watchGetCategoryAction() {
  yield takeLatest(actionTypes.GET_DATA_CATEGORY, getCategoryAction)
}

function* watchEditCategoryAction() {
  yield takeLatest(actionTypes.EDIT_DATA_CATEGORY, editCategoryAction)
}

function* watchDelCategoryAction() {
  yield takeLatest(actionTypes.DELETE_DATA_CATEGORY, delCategoryAction)
}

/* ======== Fabric no and detail ======== */

function* watchGetFabricDetaiAction() {
  yield takeLatest(actionTypes.GET_DATA_DETAIL, getFabricDetailAction)
}

function* watchSetFabricDetaiAction() {
  yield takeLatest(actionTypes.SET_DATA_DETAIL, setFabricDetailAction)
}

function* watchEditFabricDetailAction() {
  yield takeLatest(actionTypes.EDIT_DATA_DETAIL, editFabricDetailAction)
}

function* watchDelFabricDetailAction() {
  yield takeLatest(actionTypes.DELETE_DATA_DETAIL, delFabricDetailAction)
}

/* ============= Hospital ============= */
function* watchGetHospitalAction() {
  yield takeLatest(actionTypes.GET_DATA_HOSPITAL, getHospitalAction)
}

function* watchSetHospitalAction() {
  yield takeLatest(actionTypes.SET_DATA_HOSPITAL, setHospitalAction)
}

function* watchEditHospitalAction() {
  yield takeLatest(actionTypes.EDIT_DATA_HOSPITAL, editHospitalAction)
}

function* watchDelHospitalAction() {
  yield takeLatest(actionTypes.DELETE_DATA_HOSPITAL, delHospitalAction)
}

/* ============= Fabric wast ============= */
function* watchGetFabricWastAction() {
  yield takeLatest(actionTypes.GET_DATA_WASTING, getFabricWastAction)
}

function* watchSetFabricWastAction() {
  yield takeLatest(actionTypes.SET_DATA_WASTING, setFabricWastAction)
}

function* watchEditFabricWastAction() {
  yield takeLatest(actionTypes.EDIT_DATA_WASTING, editFabricWastAction)
}

function* watchDelFabricWastAction() {
  yield takeLatest(actionTypes.DELETE_DATA_WASTING, delFabricWastAction)
}

/* ======== Maximun Fabric loss count ======== */
function* watchGetFabricLossCountAction() {
  yield takeLatest(actionTypes.GET_DATA_FABRICLOSS, getFabricLossCountAction)
}

function* watchAddOrEditFabricLossCountAction() {
  yield takeLatest(actionTypes.SET_DATA_FABRICLOSS, addOreditFabricLossCountAction)
}

/* ======== Special case ======== */
function* watchGetSpecialCaseAction() {
  yield takeLatest(actionTypes.GET_DATA_SPECIALCASE, getSpecialCaseAction)
}

function* watchSetSpecialCaseAction() {
  yield takeLatest(actionTypes.SET_DATA_SPECIALCASE, setSpecialCaseAction)
}

function* watchEditSpecialCaseAction() {
  yield takeLatest(actionTypes.EDIT_DATA_SPECIALCASE, editSpecialCaseAction)
}

function* watchDelSpecialCaseAction() {
  yield takeLatest(actionTypes.DELETE_DATA_SPECIALCASE, delSpecialCaseAction)
}

/* ======== Special tag ======== */
function* watchGetSpecialTagAction() {
  yield takeLatest(actionTypes.GET_DATA_SPECIALTAG, getSpecialTagAction)
}

function* watchSetOREditSpecialTagAction() {
  yield takeLatest(actionTypes.SET_EDIT_DATA_SPECIALTAG, setOReditSpecialTagAction)
}

function* watchDelSpecialTagAction() {
  yield takeLatest(actionTypes.DELETE_DATA_SPECIALTAG, delSpecialTagAction)
}

/* ======== Reader worker ======== */
function* watchGetRFIDTagReaderWorkerAction() {
  yield takeLatest(actionTypes.GET_DATA_READERWORKER_RFIDTAG, getRFIDTagReaderWorkerAction)
}

function* watchEditRFIDTagReaderWorkerAction() {
  yield takeLatest(actionTypes.EDIT_DATA_READERWORKER_RFIDTAG, editRFIDTagReaderWorkerAction)
}

/* ======== Info Reader worker ======== */
function* watchRunEquipmentInfoManualAction() {
  yield takeLatest(actionTypes.RUN_MANUAL_DATA_INFO_EQUIPMENT, runEquipmentInfoManualAction)
}

function* watchGetRFIDTagInfoReaderWorkerAction() {
  yield takeLatest(actionTypes.GET_DATA_READERWORKER_INFO_RFIDTAG, getRFIDTagInfoReaderWorkerAction)
}

function* watchEditRFIDTagInfoReaderWorkerAction() {
  yield takeLatest(
    actionTypes.EDIT_DATA_READERWORKER_INFO_RFIDTAG,
    editRFIDTagInfoReaderWorkerAction,
  )
}

function* watchDelRFIDTagInfoReaderWorkerAction() {
  yield takeLatest(
    actionTypes.DELETE_DATA_READERWORKER_INFO_RFIDTAG,
    delRFIDTagInfoReaderWorkerAction,
  )
}

/* ======== Fabric info ======== */
function* watchSetOREditFabricInfoAction() {
  yield takeLatest(actionTypes.SET_DATA_FABRIC_INFO, setORupdateFabricInfoAction)
}

function* watchGetFabricInfoAction() {
  yield takeLatest(actionTypes.GET_DATA_FABRIC_INFO, getFabricInfoAction)
}

function* watchEditFabricInfoAction() {
  yield takeLatest(actionTypes.EDIT_DATA_FABRIC_INFO, editFabricInfoAction)
}

function* watchDelFabricInfoAction() {
  yield takeLatest(actionTypes.DELETE_DATA_FABRIC_INFO, delFabricInfoAction)
}

/* ============= Company ============= */
function* watchGetCompanyAction() {
  yield takeLatest(actionTypes.GET_DATA_COMPANY, getCompanyAction)
}

function* watchSetCompanyAction() {
  yield takeLatest(actionTypes.SET_DATA_COMPANY, setCompanyAction)
}

function* watchEditCompanyAction() {
  yield takeLatest(actionTypes.EDIT_DATA_COMPANY, editCompanyAction)
}

function* watchDelCompanyAction() {
  yield takeLatest(actionTypes.DELETE_DATA_COMPANY, delCompanyAction)
}

/* ======== company special tag ======== */
function* watchRunEquipmentCompanyAction() {
  yield takeLatest(actionTypes.EDIT_DATA_READERWORKER_COMPANY_RFIDTAG, runEquipmentCompanyAction)
}

function* watchGetEquipmentCompanyAction() {
  yield takeLatest(actionTypes.GET_DATA_READERWORKER_COMPANY_RFIDTAG, getEquipmentCompanyAction)
}

function* watchDelEquipmentCompanyAction() {
  yield takeLatest(actionTypes.DELETE_DATA_READERWORKER_COMPANY_RFIDTAG, delEquipmentCompanyAction)
}

/* ======== Company tag ======== */
function* watchGetCompanyTagAction() {
  yield takeLatest(actionTypes.GET_DATA_COMPANYTAG, getCompanyTagAction)
}

function* watchSetOREditCompanyTagAction() {
  yield takeLatest(actionTypes.SET_EDIT_DATA_COMPANYTAG, setOReditCompanyTagAction)
}

function* watchDelCompanyTagAction() {
  yield takeLatest(actionTypes.DELETE_DATA_COMPANYTAG, delCompanyTagAction)
}

/* ======== Location ward ======== */
function* watchGetLocationWardAction() {
  yield takeLatest(actionTypes.GET_DATA_LOCATIONWARD, getLocationWardAction)
}

function* watchSetLocationWardAction() {
  yield takeLatest(actionTypes.SET_DATA_LOCATIONWARD, setLocationWardAction)
}

function* watchSetFabricLocationWardAction() {
  yield takeLatest(actionTypes.SET_FABRIC_DATA_LOCATIONWARD, setFabricLocationWardAction)
}

function* watchDelFabricLocationWardAction() {
  yield takeLatest(actionTypes.DELETE_FABRIC_DATA_LOCATIONWARD, delFabricLocationWardAction)
}

function* watchDelLocationWardAction() {
  yield takeLatest(actionTypes.DELETE_DATA_LOCATIONWARD, delLocationWardAction)
}

export default function* rootSaga() {
  yield all([
    watchGetLocationWardAction(),
    watchSetLocationWardAction(),
    watchSetFabricLocationWardAction(),
    watchDelFabricLocationWardAction(),
    watchDelLocationWardAction(),

    watchGetCompanyTagAction(),
    watchSetOREditCompanyTagAction(),
    watchDelCompanyTagAction(),

    watchRunEquipmentCompanyAction(),
    watchGetEquipmentCompanyAction(),
    watchDelEquipmentCompanyAction(),

    watchGetCompanyAction(),
    watchSetCompanyAction(),
    watchEditCompanyAction(),
    watchDelCompanyAction(),

    watchSetCategoryAction(),
    watchGetCategoryAction(),
    watchEditCategoryAction(),
    watchDelCategoryAction(),

    watchGetFabricDetaiAction(),
    watchSetFabricDetaiAction(),
    watchEditFabricDetailAction(),
    watchDelFabricDetailAction(),

    watchGetHospitalAction(),
    watchSetHospitalAction(),
    watchEditHospitalAction(),
    watchDelHospitalAction(),

    watchGetFabricWastAction(),
    watchSetFabricWastAction(),
    watchEditFabricWastAction(),
    watchDelFabricWastAction(),

    watchGetFabricLossCountAction(),
    watchAddOrEditFabricLossCountAction(),

    watchGetSpecialCaseAction(),
    watchSetSpecialCaseAction(),
    watchEditSpecialCaseAction(),
    watchDelSpecialCaseAction(),

    watchGetSpecialTagAction(),
    watchSetOREditSpecialTagAction(),
    watchDelSpecialTagAction(),

    watchGetRFIDTagReaderWorkerAction(),
    watchEditRFIDTagReaderWorkerAction(),

    watchRunEquipmentInfoManualAction(),
    watchGetRFIDTagInfoReaderWorkerAction(),
    watchEditRFIDTagInfoReaderWorkerAction(),
    watchDelRFIDTagInfoReaderWorkerAction(),

    watchSetOREditFabricInfoAction(),
    watchGetFabricInfoAction(),
    watchEditFabricInfoAction(),
    watchDelFabricInfoAction(),

    watchGetFabricExpiredAction(),
    watchAddOrEditFabricExpiredUsedAction(),
    watchAddOreditFabricExpiredMonthAction(),

    watchRunEquipmentRemoveRFIDWastingTagAction(),
    watchGetEquipmentRemoveRFIDWastingTagAction(),
    watchDelEquipmentRemoveRFIDWastingTagAction(),
  ])
}
