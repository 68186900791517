import axios from 'axios'
import store from 'store'
import { notification } from 'antd'

const apiClient = axios.create({
  // baseURL: 'https://www.soccer789.com/',
  baseURL: 'http://150.95.90.140/',
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers = { 'x-auth-token': accessToken }
  }

  return request
})

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error
  if (response) {
    const { data, status } = response
    if (status === 400) {
      // check code
      // if code === 10 -> dispatch clear token
      if (data.code === 10) throw data
    }

    if (data) {
      const { msg } = data
      if (msg)
        notification.warning({
          message: msg,
        })
    }
  }
})

export default apiClient
