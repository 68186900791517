const actiontypes = {
  SET_STATE: 'management/SET_STATE',
  SET_STATE_DATA: 'management/SET_STATE_DATA',

  SET_DATA_CATEGORY: 'management/SET_DATA_CATEGORY',
  GET_DATA_CATEGORY: 'management/GET_DATA_CATEGORY',
  EDIT_DATA_CATEGORY: 'management/EDIT_DATA_CATEGORY',
  DELETE_DATA_CATEGORY: 'management/DELETE_DATA_CATEGORY',

  SET_STATE_DETAIL_DATA: 'management/SET_STATE_DETAIL_DATA',
  GET_DATA_DETAIL: 'management/GET_DATA_DETAIL',
  SET_DATA_DETAIL: 'management/SET_DATA_DETAIL',
  EDIT_DATA_DETAIL: 'management/EDIT_DATA_DETAIL',
  DELETE_DATA_DETAIL: 'management/DELETE_DATA_DETAIL',

  SET_STATE_HOSPITAL_DATA: 'management/SET_STATE_HOSPITAL_DATA',
  GET_DATA_HOSPITAL: 'management/GET_DATA_HOSPITAL',
  SET_DATA_HOSPITAL: 'management/SET_DATA_HOSPITAL',
  EDIT_DATA_HOSPITAL: 'management/EDIT_DATA_HOSPITAL',
  DELETE_DATA_HOSPITAL: 'management/DELETE_DATA_HOSPITAL',

  SET_STATE_WASTING_DATA: 'management/SET_STATE_WASTING_DATA',
  GET_DATA_WASTING: 'management/GET_DATA_WASTING',
  SET_DATA_WASTING: 'management/SET_DATA_WASTING',
  EDIT_DATA_WASTING: 'management/EDIT_DATA_WASTING',
  DELETE_DATA_WASTING: 'management/DELETE_DATA_WASTING',

  SET_STATE_FABRICLOSS_DATA: 'management/SET_STATE_FABRICLOSS_DATA',
  GET_DATA_FABRICLOSS: 'management/GET_DATA_FABRICLOSS',
  SET_DATA_FABRICLOSS: 'management/SET_DATA_FABRICLOSS',

  SET_STATE_SPECIALCASE_DATA: 'management/SET_STATE_SPECIALCASE_DATA',
  GET_DATA_SPECIALCASE: 'management/GET_DATA_SPECIALCASE',
  SET_DATA_SPECIALCASE: 'management/SET_DATA_SPECIALCASE',
  EDIT_DATA_SPECIALCASE: 'management/EDIT_DATA_SPECIALCASE',
  DELETE_DATA_SPECIALCASE: 'management/DELETE_DATA_SPECIALCASE',

  SET_STATE_SPECIALTAG_DATA: 'management/SET_STATE_SPECIALTAG_DATA',
  GET_DATA_SPECIALTAG: 'management/GET_DATA_SPECIALTAG',
  SET_EDIT_DATA_SPECIALTAG: 'management/SET_EDIT_DATA_SPECIALTAG',
  DELETE_DATA_SPECIALTAG: 'management/DELETE_DATA_SPECIALTAG',

  SET_STATE_READERWORKER_RFIDTAG_DATA: 'management/SET_STATE_READERWORKER_RFIDTAG_DATA',
  GET_DATA_READERWORKER_RFIDTAG: 'management/GET_DATA_READERWORKER_RFIDTAG',
  EDIT_DATA_READERWORKER_RFIDTAG: 'management/EDIT_DATA_READERWORKER_RFIDTAG',

  RUN_MANUAL_DATA_INFO_EQUIPMENT: 'management/RUN_MANUAL_DATA_INFO_EQUIPMENT',
  SET_STATE_READERWORKER_INFO_RFIDTAG_DATA: 'management/SET_STATE_READERWORKER_INFO_RFIDTAG_DATA',
  GET_DATA_READERWORKER_INFO_RFIDTAG: 'management/GET_DATA_READERWORKER_INFO_RFIDTAG',
  EDIT_DATA_READERWORKER_INFO_RFIDTAG: 'management/EDIT_DATA_READERWORKER_INFO_RFIDTAG',
  DELETE_DATA_READERWORKER_INFO_RFIDTAG: 'management/DELETE_DATA_READERWORKER_INFO_RFIDTAG',

  SET_STATE_FABRIC_INFO_DATA: 'management/SET_STATE_FABRIC_INFO_DATA',
  GET_DATA_FABRIC_INFO: 'management/GET_DATA_FABRIC_INFO',
  SET_DATA_FABRIC_INFO: 'management/SET_DATA_FABRIC_INFO',
  EDIT_DATA_FABRIC_INFO: 'management/EDIT_DATA_FABRIC_INFO',
  DELETE_DATA_FABRIC_INFO: 'management/DELETE_DATA_FABRIC_INFO',

  SET_STATE_COMPANY_DATA: 'management/SET_STATE_COMPANY_DATA',
  SET_DATA_COMPANY: 'management/SET_DATA_COMPANY',
  GET_DATA_COMPANY: 'management/GET_DATA_COMPANY',
  EDIT_DATA_COMPANY: 'management/EDIT_DATA_COMPANY',
  DELETE_DATA_COMPANY: 'management/DELETE_DATA_COMPANY',

  SET_STATE_READERWORKER_COMPANY_RFIDTAG_DATA:
    'management/SET_STATE_READERWORKER_COMPANY_RFIDTAG_DATA',
  GET_DATA_READERWORKER_COMPANY_RFIDTAG: 'management/GET_DATA_READERWORKER_COMPANY_RFIDTAG',
  EDIT_DATA_READERWORKER_COMPANY_RFIDTAG: 'management/EDIT_DATA_READERWORKER_COMPANY_RFIDTAG',
  DELETE_DATA_READERWORKER_COMPANY_RFIDTAG: 'management/DELETE_DATA_READERWORKER_COMPANY_RFIDTAG',

  SET_STATE_COMPANYTAG_DATA: 'management/SET_STATE_COMPANYTAG_DATA',
  GET_DATA_COMPANYTAG: 'management/GET_DATA_COMPANYTAG',
  SET_EDIT_DATA_COMPANYTAG: 'management/SET_EDIT_DATA_COMPANYTAG',
  DELETE_DATA_COMPANYTAG: 'management/DELETE_DATA_COMPANYTAG',

  SET_STATE_LOCATIONWARD_DATA: 'management/SET_STATE_LOCATIONWARD_DATA',
  GET_DATA_LOCATIONWARD: 'management/GET_DATA_LOCATIONWARD',
  SET_DATA_LOCATIONWARD: 'management/SET_DATA_LOCATIONWARD',
  SET_FABRIC_DATA_LOCATIONWARD: 'management/SET_FABRIC_DATA_LOCATIONWARD',
  DELETE_FABRIC_DATA_LOCATIONWARD: 'management/DELETE_FABRIC_DATA_LOCATIONWARD',
  DELETE_DATA_LOCATIONWARD: 'management/DELETE_DATA_LOCATIONWARD',

  SET_STATE_FABRICEXPIRED_DATA: 'management/SET_STATE_FABRICEXPIRED_DATA',
  GET_DATA_FABRICEXPIRED: 'management/GET_DATA_FABRICEXPIRED',
  SET_DATA_FABRICEXPIREDMONTH: 'management/SET_DATA_FABRICEXPIRED_MONTH',
  SET_DATA_FABRICEXPIREDUSED: 'management/SET_DATA_FABRICEXPIRED_USED',

  SET_STATE_READERWORKER_REMOVE_WASTING_TAG_DATA:
    'management/SET_STATE_READERWORKER_REMOVE_WASTING_TAG_DATA',
  EDIT_DATA_READERWORKER_REMOVE_WASTING_TAG: 'management/EDIT_DATA_READERWORKER_REMOVE_WASTING_TAG',
  GET_DATA_READERWORKER_REMOVE_WASTING_TAG: 'management/GET_DATA_READERWORKER_REMOVE_WASTING_TAG',
  DELETE_DATA_READERWORKER_REMOVE_WASTING_TAG:
    'management/DELETE_DATA_READERWORKER_REMOVE_WASTING_TAG',
}

export default actiontypes
