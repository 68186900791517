import actionTypes from './actiontypes'

const initialState = {
  isLoading: false,
  fabricCategory: {},
  fabricDetail: {},
  hospital: {},
  fabricWast: {},
  readerworker_fabricWast: {},
  maxfabricLoss: {},
  specialcase: {},
  specialtag: {},
  readerworker: {},
  inforeaderworker: {},
  fabricinfo: {},

  company: {},
  readerworker_company: {},
  companytag: {},

  locationward: {},
  readerworker_locationward: {},
  locationward_tag: {},

  fabricExpired: {},
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_STATE:
      return { ...state, ...action.payload }

    case actionTypes.SET_STATE_DATA:
      return { ...state, fabricCategory: action.payload }

    case actionTypes.SET_STATE_DETAIL_DATA:
      return { ...state, fabricDetail: action.payload }

    case actionTypes.SET_STATE_HOSPITAL_DATA:
      return { ...state, hospital: action.payload }

    case actionTypes.SET_STATE_WASTING_DATA:
      return { ...state, fabricWast: action.payload }

    case actionTypes.SET_STATE_FABRICLOSS_DATA:
      return { ...state, maxfabricLoss: action.payload }

    case actionTypes.SET_STATE_SPECIALCASE_DATA:
      return { ...state, specialcase: action.payload }

    case actionTypes.SET_STATE_SPECIALTAG_DATA:
      return { ...state, specialtag: action.payload }

    case actionTypes.SET_STATE_READERWORKER_RFIDTAG_DATA:
      return { ...state, readerworker: action.payload }

    case actionTypes.SET_STATE_READERWORKER_INFO_RFIDTAG_DATA:
      return { ...state, inforeaderworker: action.payload }

    case actionTypes.SET_STATE_FABRIC_INFO_DATA:
      return { ...state, fabricinfo: action.payload }

    case actionTypes.SET_STATE_COMPANY_DATA:
      return { ...state, company: action.payload }

    case actionTypes.SET_STATE_READERWORKER_COMPANY_RFIDTAG_DATA:
      return { ...state, readerworker_company: action.payload }

    case actionTypes.SET_STATE_COMPANYTAG_DATA:
      return { ...state, companytag: action.payload }

    case actionTypes.SET_STATE_LOCATIONWARD_DATA:
      return { ...state, locationward: action.payload }

    case actionTypes.SET_STATE_FABRICEXPIRED_DATA:
      return { ...state, fabricExpired: action.payload }

    case actionTypes.SET_STATE_READERWORKER_REMOVE_WASTING_TAG_DATA:
      return { ...state, readerworker_fabricWast: action.payload }

    default:
      return state
  }
}
