/* eslint-disable import/prefer-default-export */
import apiClient from 'services/axios'

export async function fetchMonitorProcessData() {
  return apiClient
    .get(`/rfidsystems/monitorprocess`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}
