/* eslint-disable import/prefer-default-export */
import { call, put } from 'redux-saga/effects'
import actionTypes from './actiontypes'
import {
  fetchCategoryData,
  delCategoryData,
  editCategoryData,
  setCategoryData,
  fetchFabricDetailData,
  setFabricDetailData,
  editFabricDetailData,
  delFabricDetailData,
  fetchHospitalData,
  setHospitalData,
  editHospitalData,
  delHospitalData,
  fetchFabricWastData,
  setFabricWastData,
  editFabricWastData,
  delFabricWastData,
  fetchFabricLossCountData,
  addOreditFabricLossCountData,
  fetchSpecialCaseData,
  setSpecialCaseData,
  editSpecialCaseData,
  delSpecialCaseData,
  fetchSpecialTagData,
  setOReditSpecialTagData,
  delSpecialTagData,
  fetRFIDTagReaderWorkerData,
  editRFIDTagReaderWorkerData,
  runEquipmentInfoManualData,
  fetRFIDTagInfoReaderWorkerData,
  editRFIDTagInfoReaderWorkerData,
  delRFIDTagInfoReaderWorkerData,
  fetchFabricInfoData,
  addOrupdateFabricInfoData,
  editFabricInfoData,
  delFabricInfoData,
  setCompanyData,
  fetchCompanyData,
  editCompanyData,
  delCompanyData,
  runEquipmentCompanyData,
  fetchEquipmentCompanyTagData,
  delEquipmentCompanyTagData,
  fetchCompanyTagData,
  setOReditCompanyTagData,
  delCompanyTagData,
  fetchLocationWardData,
  setLocationWardData,
  setFabricLocationWardData,
  delFabricLocationWardData,
  delLocationWardData,
  addOreditFabricExpiredUsedData,
  addOreditFabricExpiredMonthData,
  fetchFabricExpiredData,
  runEquipmentRemoveRFIDWastingTagData,
  fetchEquipmentRemoveRFIDWastingTagData,
  delEquipmentRemoveRFIDWastingTagData,
} from '../../services/management'

/* ======== remove RFID wasting tag worker ======== */
export function* runEquipmentRemoveRFIDWastingTagAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(runEquipmentRemoveRFIDWastingTagData, data.payload)
    yield put({ type: actionTypes.SET_STATE_READERWORKER_REMOVE_WASTING_TAG_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* getEquipmentRemoveRFIDWastingTagAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } })
    const payload = yield call(fetchEquipmentRemoveRFIDWastingTagData)
    yield put({ type: actionTypes.SET_STATE_READERWORKER_REMOVE_WASTING_TAG_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* delEquipmentRemoveRFIDWastingTagAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(delEquipmentRemoveRFIDWastingTagData)
    yield put({ type: actionTypes.SET_STATE_READERWORKER_REMOVE_WASTING_TAG_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

/* ======== Fabric Expired ======== */
export function* getFabricExpiredAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } })
    const payload = yield call(fetchFabricExpiredData)
    yield put({ type: actionTypes.SET_STATE_FABRICEXPIRED_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* addOreditFabricExpiredUsedAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(addOreditFabricExpiredUsedData, data.payload)
    yield put({ type: actionTypes.SET_STATE_FABRICEXPIRED_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* addOreditFabricExpiredMonthAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(addOreditFabricExpiredMonthData, data.payload)
    yield put({ type: actionTypes.SET_STATE_FABRICEXPIRED_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

/* ======== Fabric Category ======== */
export function* setCategoryAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(setCategoryData, data.payload)
    yield put({ type: actionTypes.SET_STATE_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* getCategoryAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } })
    const payload = yield call(fetchCategoryData)
    yield put({ type: actionTypes.SET_STATE_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* editCategoryAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(editCategoryData, data.payload)
    yield put({ type: actionTypes.SET_STATE_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* delCategoryAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(delCategoryData, data.payload)
    yield put({ type: actionTypes.SET_STATE_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

/* ======== Fabric no and detail ======== */
export function* getFabricDetailAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } })
    const payload = yield call(fetchFabricDetailData)
    yield put({ type: actionTypes.SET_STATE_DETAIL_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* setFabricDetailAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(setFabricDetailData, data.payload)
    yield put({ type: actionTypes.SET_STATE_DETAIL_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* editFabricDetailAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(editFabricDetailData, data.payload)
    yield put({ type: actionTypes.SET_STATE_DETAIL_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* delFabricDetailAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(delFabricDetailData, data.payload)
    yield put({ type: actionTypes.SET_STATE_DETAIL_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

/* ======== Hospital ======== */
export function* getHospitalAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } })
    const payload = yield call(fetchHospitalData)
    yield put({ type: actionTypes.SET_STATE_HOSPITAL_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* setHospitalAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(setHospitalData, data.payload)
    yield put({ type: actionTypes.SET_STATE_HOSPITAL_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* editHospitalAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(editHospitalData, data.payload)
    yield put({ type: actionTypes.SET_STATE_HOSPITAL_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* delHospitalAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(delHospitalData, data.payload)
    yield put({ type: actionTypes.SET_STATE_HOSPITAL_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

/* ======== Fabric wast ======== */
export function* getFabricWastAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } })
    const payload = yield call(fetchFabricWastData)
    yield put({ type: actionTypes.SET_STATE_WASTING_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* setFabricWastAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(setFabricWastData, data.payload)
    yield put({ type: actionTypes.SET_STATE_WASTING_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* editFabricWastAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(editFabricWastData, data.payload)
    yield put({ type: actionTypes.SET_STATE_WASTING_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* delFabricWastAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(delFabricWastData, data.payload)
    yield put({ type: actionTypes.SET_STATE_WASTING_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

/* ======== Fabric loss ======== */
export function* getFabricLossCountAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } })
    const payload = yield call(fetchFabricLossCountData)
    yield put({ type: actionTypes.SET_STATE_FABRICLOSS_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* addOreditFabricLossCountAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(addOreditFabricLossCountData, data.payload)
    yield put({ type: actionTypes.SET_STATE_FABRICLOSS_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

/* ======== Special case ======== */
export function* getSpecialCaseAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } })
    const payload = yield call(fetchSpecialCaseData)
    yield put({ type: actionTypes.SET_STATE_SPECIALCASE_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* setSpecialCaseAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(setSpecialCaseData, data.payload)
    yield put({ type: actionTypes.SET_STATE_SPECIALCASE_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* editSpecialCaseAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(editSpecialCaseData, data.payload)
    yield put({ type: actionTypes.SET_STATE_SPECIALCASE_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* delSpecialCaseAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(delSpecialCaseData, data.payload)
    yield put({ type: actionTypes.SET_STATE_SPECIALCASE_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

/* ======== Special Tag ======== */
export function* getSpecialTagAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } })
    const payload = yield call(fetchSpecialTagData)
    yield put({ type: actionTypes.SET_STATE_SPECIALTAG_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* setOReditSpecialTagAction(data) {
  console.log(data)
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(setOReditSpecialTagData, data.payload)
    yield put({ type: actionTypes.SET_STATE_SPECIALTAG_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* delSpecialTagAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(delSpecialTagData, data.payload)
    yield put({ type: actionTypes.SET_STATE_SPECIALTAG_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

/* ======== Reader worker ======== */
export function* getRFIDTagReaderWorkerAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(fetRFIDTagReaderWorkerData, data.payload)
    yield put({ type: actionTypes.SET_STATE_READERWORKER_RFIDTAG_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* editRFIDTagReaderWorkerAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(editRFIDTagReaderWorkerData, data.payload)
    yield put({ type: actionTypes.SET_STATE_READERWORKER_RFIDTAG_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

/* ======== Info Reader worker ======== */
export function* runEquipmentInfoManualAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(runEquipmentInfoManualData, data.payload)
    yield put({ type: actionTypes.SET_STATE_READERWORKER_INFO_RFIDTAG_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* getRFIDTagInfoReaderWorkerAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(fetRFIDTagInfoReaderWorkerData, data.payload)
    yield put({ type: actionTypes.SET_STATE_READERWORKER_INFO_RFIDTAG_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* editRFIDTagInfoReaderWorkerAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(editRFIDTagInfoReaderWorkerData, data.payload)
    yield put({ type: actionTypes.SET_STATE_READERWORKER_INFO_RFIDTAG_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* delRFIDTagInfoReaderWorkerAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(delRFIDTagInfoReaderWorkerData, data.payload)
    yield put({ type: actionTypes.SET_STATE_READERWORKER_INFO_RFIDTAG_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

/* ======== Fabric info ======== */
export function* getFabricInfoAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(fetchFabricInfoData)
    yield put({ type: actionTypes.SET_STATE_FABRIC_INFO_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* setORupdateFabricInfoAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(addOrupdateFabricInfoData, data.payload)
    yield put({ type: actionTypes.SET_STATE_FABRIC_INFO_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* editFabricInfoAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(editFabricInfoData, data.payload)
    yield put({ type: actionTypes.SET_STATE_FABRIC_INFO_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* delFabricInfoAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(delFabricInfoData, data.payload)
    yield put({ type: actionTypes.SET_STATE_FABRIC_INFO_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

/* ======== Company ======== */
export function* getCompanyAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } })
    const payload = yield call(fetchCompanyData)
    yield put({ type: actionTypes.SET_STATE_COMPANY_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* setCompanyAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(setCompanyData, data.payload)
    yield put({ type: actionTypes.SET_STATE_COMPANY_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* editCompanyAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(editCompanyData, data.payload)
    yield put({ type: actionTypes.SET_STATE_COMPANY_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* delCompanyAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(delCompanyData, data.payload)
    yield put({ type: actionTypes.SET_STATE_COMPANY_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

/* ======== company special tag ======== */
export function* runEquipmentCompanyAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(runEquipmentCompanyData, data.payload)
    yield put({ type: actionTypes.SET_STATE_READERWORKER_COMPANY_RFIDTAG_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* getEquipmentCompanyAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } })
    const payload = yield call(fetchEquipmentCompanyTagData)
    yield put({ type: actionTypes.SET_STATE_READERWORKER_COMPANY_RFIDTAG_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* delEquipmentCompanyAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(delEquipmentCompanyTagData)
    yield put({ type: actionTypes.SET_STATE_READERWORKER_COMPANY_RFIDTAG_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

/* ======== Company Tag ======== */
export function* getCompanyTagAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } })
    const payload = yield call(fetchCompanyTagData)
    yield put({ type: actionTypes.SET_STATE_COMPANYTAG_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* setOReditCompanyTagAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(setOReditCompanyTagData, data.payload)
    yield put({ type: actionTypes.SET_STATE_COMPANYTAG_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* delCompanyTagAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(delCompanyTagData, data.payload)
    yield put({ type: actionTypes.SET_STATE_COMPANYTAG_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

/* ======== Location ward ======== */
export function* getLocationWardAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } })
    const payload = yield call(fetchLocationWardData)
    yield put({ type: actionTypes.SET_STATE_LOCATIONWARD_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* setLocationWardAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(setLocationWardData, data.payload)
    yield put({ type: actionTypes.SET_STATE_LOCATIONWARD_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* setFabricLocationWardAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(setFabricLocationWardData, data.payload)
    yield put({ type: actionTypes.SET_STATE_LOCATIONWARD_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* delFabricLocationWardAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(delFabricLocationWardData, data.payload)
    yield put({ type: actionTypes.SET_STATE_LOCATIONWARD_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* delLocationWardAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(delLocationWardData, data.payload)
    yield put({ type: actionTypes.SET_STATE_LOCATIONWARD_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}
