import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import * as firebase from 'services/firebase'
import * as jwt from 'services/jwt'
import actions from './actions'

const mapAuthProviders = {
  firebase: {
    login: firebase.login,
    register: firebase.register,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
  },
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
    addUser: jwt.addUser,
    getAllUser: jwt.getAllUser,
    removeUser: jwt.removeUser,
  },
}

export function* ADD_USER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].addUser, payload)

  if (success) {
    notification.success({
      message: 'เพิ่มผู้ใช้ใหม่สำเร็จ',
      description: '',
    })

    yield put({
      type: 'user/SET_STATE_USER',
      payload: {
        ...success.data,
        isLoading: false,
      },
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        isLoading: false,
      },
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        isLoading: false,
      },
    })
  }

  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOGIN({ payload }) {
  const { username, password } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].login, username, password)
  if (success) {
    if (success.data) {
      const { result = { status: 0 } } = success.data
      const { status = 0 } = result
      if (status === 3) {
        yield history.push('/auth/result')
        yield put({
          type: 'user/SET_STATE',
          payload: {
            ...success.data,
            isLoading: false,
          },
        })
      }
    } else {
      yield put({
        type: 'user/LOAD_CURRENT_ACCOUNT',
      })
      yield history.push('/')
      notification.success({
        message: 'Logged In',
        description: 'You have successfully logged in!',
      })
    }
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* REGISTER({ payload }) {
  const { email, password, name } = payload
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[authProvider].register, email, password, name)
  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
    yield history.push('/')
    notification.success({
      message: 'Succesful Registered',
      description: 'You have successfully registered!',
    })
  }
  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const { authProvider } = yield select(state => state.settings)
  const response = yield call(mapAuthProviders[authProvider].currentAccount)
  if (response) {
    // eslint-disable-next-line
    const { _id, username, name, surname, note, created, acctype, employee_id, division } = response
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id: _id,
        username,
        name,
        surname,
        note,
        created,
        acctype,
        employee_id,
        division,
        authorized: true,
      },
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id: '',
        username: '',
        name: '',
        surname: '',
        note: '',
        created: '',
        acctype: '',
        employee_id: 0,
        division: 0,
        authorized: true,
      },
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  const { authProvider } = yield select(state => state.settings)
  yield call(mapAuthProviders[authProvider].logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      username: '',
      name: '',
      surname: '',
      note: '',
      created: '',
      acctype: '',
      employee_id: '',
      division: '',
      authorized: false,
      loading: false,
    },
  })
}

export function* GET_ALL_USER_DATA() {
  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].getAllUser)
  if (success) {
    yield put({
      type: 'user/SET_STATE_USER',
      payload: {
        ...success.data,
      },
    })
  }
}

export function* REMOVE_USER({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { authProvider: autProviderName } = yield select(state => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].removeUser, payload)
  console.log(success)
  if (success) {
    notification.success({
      message: 'ลบผู้ใช้ใหม่สำเร็จ',
      description: '',
    })

    yield put({
      type: 'user/SET_STATE_USER',
      payload: {
        ...success.data,
        isLoading: false,
      },
    })
    yield put({
      type: 'user/SET_STATE',
      payload: {
        isLoading: false,
      },
    })
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        isLoading: false,
      },
    })
  }

  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.ADD_USER, ADD_USER),
    takeEvery(actions.REMOVE_USER, REMOVE_USER),
    takeEvery(actions.GET_ALL_USER_DATA, GET_ALL_USER_DATA),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
