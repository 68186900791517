/* eslint-disable import/prefer-default-export */
import apiClient from 'services/axios'

/* ======== remove RFID wasting tag worker ======== */
export async function runEquipmentRemoveRFIDWastingTagData(data) {
  return apiClient
    .post(`/rfidsystems/wastingcaseworker/runequipment`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function fetchEquipmentRemoveRFIDWastingTagData() {
  return apiClient
    .get(`/rfidsystems/wastingcaseworker`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function delEquipmentRemoveRFIDWastingTagData() {
  return apiClient
    .delete(`/rfidsystems/wastingcaseworker`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

/* ======== Fabric expired ======== */
export async function fetchFabricExpiredData() {
  return apiClient
    .get(`/rfidsystems/fabricexpired`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function addOreditFabricExpiredMonthData(data) {
  return apiClient
    .post(`/rfidsystems/fabricexpiredmonth`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function addOreditFabricExpiredUsedData(data) {
  return apiClient
    .post(`/rfidsystems/fabricexpiredused`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

/* ======== Fabric Category ======== */
export async function setCategoryData(data) {
  return apiClient
    .post(`/rfidsystems/fabriccategory`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function fetchCategoryData() {
  return apiClient
    .get(`/rfidsystems/fabriccategory`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function editCategoryData(data) {
  return apiClient
    .put(`/rfidsystems/fabriccategory`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function delCategoryData(data) {
  return apiClient
    .delete(`/rfidsystems/fabriccategory`, {
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    })
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

/* ======== Fabric detail ======== */
export async function fetchFabricDetailData() {
  return apiClient
    .get(`/rfidsystems/fabricdetail`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function setFabricDetailData(data) {
  return apiClient
    .post(`/rfidsystems/fabricdetail`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function editFabricDetailData(data) {
  return apiClient
    .put(`/rfidsystems/fabricdetail`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function delFabricDetailData(data) {
  return apiClient
    .delete(`/rfidsystems/fabricdetail`, {
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    })
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

/* ======== Hospital ======== */
export async function setHospitalData(data) {
  return apiClient
    .post(`/rfidsystems/hospital`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function fetchHospitalData() {
  return apiClient
    .get(`/rfidsystems/hospital`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function editHospitalData(data) {
  return apiClient
    .put(`/rfidsystems/hospital`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function delHospitalData(data) {
  return apiClient
    .delete(`/rfidsystems/hospital`, {
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    })
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

/* ======== Fabric wast ======== */
export async function fetchFabricWastData() {
  return apiClient
    .get(`/rfidsystems/wastingcase`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function setFabricWastData(data) {
  return apiClient
    .post(`/rfidsystems/wastingcase`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function editFabricWastData(data) {
  return apiClient
    .put(`/rfidsystems/wastingcase`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function delFabricWastData(data) {
  return apiClient
    .delete(`/rfidsystems/wastingcase`, {
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    })
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

/* ======== Maximun Fabric loss count ======== */
export async function fetchFabricLossCountData() {
  return apiClient
    .get(`/rfidsystems/maxfabricloss`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function addOreditFabricLossCountData(data) {
  return apiClient
    .post(`/rfidsystems/maxfabricloss`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

/* ======== Special case ======== */
export async function fetchSpecialCaseData() {
  return apiClient
    .get(`/rfidsystems/specialcase`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function setSpecialCaseData(data) {
  return apiClient
    .post(`/rfidsystems/specialcase`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function editSpecialCaseData(data) {
  return apiClient
    .put(`/rfidsystems/specialcase`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function delSpecialCaseData(data) {
  return apiClient
    .delete(`/rfidsystems/specialcase`, {
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    })
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

/* ======== Special Tag ======== */

export async function fetchSpecialTagData() {
  return apiClient
    .get(`/rfidsystems/specialtag`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function setOReditSpecialTagData(data) {
  return apiClient
    .post(`/rfidsystems/specialtag`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function delSpecialTagData(data) {
  return apiClient
    .delete(`/rfidsystems/specialtag`, {
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    })
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

/* ======== Reader worker ======== */
export async function fetRFIDTagReaderWorkerData(data) {
  return apiClient
    .post(`/rfidsystems/readerworker`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function editRFIDTagReaderWorkerData(data) {
  return apiClient
    .put(`/rfidsystems/readerworker`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

/* ======== Info Reader worker ======== */
export async function fetRFIDTagInfoReaderWorkerData(data) {
  return apiClient
    .post(`/rfidsystems/inforeaderworker`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function editRFIDTagInfoReaderWorkerData(data) {
  return apiClient
    .put(`/rfidsystems/inforeaderworker`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function delRFIDTagInfoReaderWorkerData(data) {
  return apiClient
    .delete(`/rfidsystems/inforeaderworker`, {
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    })
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

/* ======== fabric info ======== */
export async function runEquipmentInfoManualData(data) {
  return apiClient
    .post(`/rfidsystems/fabricinfo/runmanual`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function fetchFabricInfoData() {
  return apiClient
    .get(`/rfidsystems/fabricinfo`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function addOrupdateFabricInfoData(data) {
  // For Add new tag
  return apiClient
    .post(`/rfidsystems/fabricinfo`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function editFabricInfoData(data) {
  return apiClient
    .post(`/rfidsystems/fabricinfo/update`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function delFabricInfoData(data) {
  return apiClient
    .delete(`/rfidsystems/fabricinfo`, {
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    })
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

/* ======== company ======== */
export async function setCompanyData(data) {
  return apiClient
    .post(`/rfidsystems/company`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function fetchCompanyData() {
  return apiClient
    .get(`/rfidsystems/company`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function editCompanyData(data) {
  return apiClient
    .put(`/rfidsystems/company`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function delCompanyData(data) {
  return apiClient
    .delete(`/rfidsystems/company`, {
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    })
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

/* ======== company special tag worker ======== */
export async function runEquipmentCompanyData(data) {
  return apiClient
    .post(`/rfidsystems/companyspecialatg/runequipment`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function fetchEquipmentCompanyTagData() {
  return apiClient
    .get(`/rfidsystems/companyspecialatg`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function delEquipmentCompanyTagData() {
  return apiClient
    .delete(`/rfidsystems/companyspecialatg`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

/* ======== Company Tag ======== */

export async function fetchCompanyTagData() {
  return apiClient
    .get(`/rfidsystems/companytag`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function setOReditCompanyTagData(data) {
  return apiClient
    .post(`/rfidsystems/companytag`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function delCompanyTagData(data) {
  return apiClient
    .delete(`/rfidsystems/companytag`, {
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    })
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

/* ======== Location ward ======== */

export async function fetchLocationWardData() {
  return apiClient
    .get(`/rfidsystems/locationward`)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function setLocationWardData(data) {
  return apiClient
    .post(`/rfidsystems/locationward`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function setFabricLocationWardData(data) {
  return apiClient
    .post(`/rfidsystems/locationward/addfabric`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function delFabricLocationWardData(data) {
  return apiClient
    .post(`/rfidsystems/locationward/removefabric`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function delLocationWardData(data) {
  return apiClient
    .delete(`/rfidsystems/locationward`, {
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    })
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}
