const actions = {
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  REGISTER: 'user/REGISTER',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  SET_STATE_USER: 'user/SET_STATE_USER',
  ADD_USER: 'user/ADD_USER',
  GET_ALL_USER_DATA: 'user/GET_ALL_USER_DATA',
  REMOVE_USER: 'user/REMOVE_USER',
}

export default actions
