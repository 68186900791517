import apiClient from 'services/axios'
import store from 'store'

export async function login(username, password) {
  return apiClient
    .post('/rfidsystems/user/login', {
      username,
      password,
    })
    .then(response => {
      if (response) {
        const { accessToken } = response.data.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function register(data) {
  return apiClient
    .post('/v2/user/registerv2', data)
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function currentAccount() {
  return apiClient
    .get('/rfidsystems/user/account')
    .then(response => {
      if (response) {
        const { accessToken } = response.data.data
        if (accessToken) {
          store.set('accessToken', accessToken)
        }
        return response.data.data
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function logout() {
  return apiClient
    .get('/rfidsystems/user/logout')
    .then(() => {
      store.remove('accessToken')
      return true
    })
    .catch(err => console.log(err))
}

export async function addUser(data) {
  return apiClient
    .post('/rfidsystems/user', data)
    .then(response => {
      return response
    })
    .catch(err => {
      throw Error(err)
    })
}

export async function getAllUser() {
  return apiClient
    .get('/rfidsystems/user')
    .then(response => {
      if (response) {
        return response
      }
      return false
    })
    .catch(err => console.log(err))
}

export async function removeUser(data) {
  return apiClient
    .delete('/rfidsystems/user', {
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    })
    .then(response => {
      if (response) {
        return response
      }
      return false
    })
    .catch(err => console.log(err))
}
