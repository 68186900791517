import actionTypes from './actiontypes'

const initialState = {
  isLoading: false,
  autoReport: {},
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_STATE:
      return { ...state, ...action.payload }

    case actionTypes.SET_STATE_AUTO_REPORT_DATA:
      return { ...state, autoReport: action.payload }

    default:
      return state
  }
}
