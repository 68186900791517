import { all, takeLatest } from 'redux-saga/effects' // eslint-disable-line
import actionTypes from './actiontypes' // eslint-disable-line
import { getMonitorProcessAction } from './actions'

/* ======== Monitor process ======== */
function* watchGetMonitorProcessAction() {
  yield takeLatest(actionTypes.GET_DATA_MONITOR_PROCESS, getMonitorProcessAction)
}

export default function* rootSaga() {
  yield all([watchGetMonitorProcessAction()])
}
