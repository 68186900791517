import actionTypes from './actiontypes'

const initialState = {
  isLoading: false,
  step: 0,
  equipment: {},
  equipmentType: {},
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_STATE:
      return { ...state, ...action.payload }

    case actionTypes.SET_STATE_EQUIPMENT_TYPE_DATA:
      return { ...state, equipmentType: action.payload }

    case actionTypes.SET_STATE_EQUIPMENT_DATA:
      return { ...state, equipment: action.payload }

    default:
      return state
  }
}
