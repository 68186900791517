/* eslint-disable import/prefer-default-export */
import { call, put } from 'redux-saga/effects'
import actionTypes from './actiontypes'
import { fetchAutoReportData } from '../../services/report'

export function* getAutoReportAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(fetchAutoReportData, data.payload)
    yield put({ type: actionTypes.SET_STATE_AUTO_REPORT_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}
