import actionTypes from './actiontypes'

const initialState = {
  isLoading: false,
  monitorProcess: {},
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_STATE:
      return { ...state, ...action.payload }

    case actionTypes.SET_STATE_MONITOR_PROCESS_DATA:
      return { ...state, monitorProcess: action.payload }

    default:
      return state
  }
}
