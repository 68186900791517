import { all, takeLatest } from 'redux-saga/effects' // eslint-disable-line
import actionTypes from './actiontypes' // eslint-disable-line
import { getAutoReportAction } from './actions'

/* ======== Auto report ======== */
function* watchGetAutoReportAction() {
  yield takeLatest(actionTypes.GET_DATA_AUTO_REPORT, getAutoReportAction)
}

export default function* rootSaga() {
  yield all([watchGetAutoReportAction()])
}
