/* eslint-disable import/prefer-default-export */
import apiClient from 'services/axios'

export async function fetchAutoReportData(data) {
  return apiClient
    .post(`/rfidsystems/autoreport`, data)
    .then(response => {
      return response.data
    })
    .catch(err => {
      throw Error(err)
    })
}
