/* eslint-disable import/prefer-default-export */
import { call, put } from 'redux-saga/effects'
import actionTypes from './actiontypes'
import {
  fetchEquipmentTypeData,
  setEquipmentTypeData,
  editEquipmentTypeData,
  delEquipmentTypeData,
  fetchEquipmentData,
  setEquipmentData,
  editEquipmentData,
  delEquipmentData,
  runEquipmentManualData,
} from '../../services/equipment'

export function* getEquipmentTypeAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } })
    const payload = yield call(fetchEquipmentTypeData)
    yield put({ type: actionTypes.SET_STATE_EQUIPMENT_TYPE_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* setEquipmentTypeAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(setEquipmentTypeData, data.payload)
    yield put({ type: actionTypes.SET_STATE_EQUIPMENT_TYPE_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* editEquipmentTypeAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(editEquipmentTypeData, data.payload)
    yield put({ type: actionTypes.SET_STATE_EQUIPMENT_TYPE_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* delEquipmentTypeAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(delEquipmentTypeData, data.payload)
    yield put({ type: actionTypes.SET_STATE_EQUIPMENT_TYPE_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* getEquipmentAction() {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: true } })
    const payload = yield call(fetchEquipmentData)
    yield put({ type: actionTypes.SET_STATE_EQUIPMENT_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* setEquipmentAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(setEquipmentData, data.payload)
    yield put({ type: actionTypes.SET_STATE_EQUIPMENT_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* editEquipmentAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(editEquipmentData, data.payload)
    yield put({ type: actionTypes.SET_STATE_EQUIPMENT_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* runEquipmentManualAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(runEquipmentManualData, data.payload)
    yield put({ type: actionTypes.SET_STATE_EQUIPMENT_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}

export function* delEquipmentAction(data) {
  /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
  try {
    const payload = yield call(delEquipmentData, data.payload)
    yield put({ type: actionTypes.SET_STATE_EQUIPMENT_DATA, payload })
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  } catch (e) {
    yield put({ type: actionTypes.SET_STATE, payload: { isLoading: false } })
  }
}
